import { useBreakpoint } from "@/hooks/useBreakpoints"
import { Dialog, Transition, TransitionChild } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { Drawer, DrawerContent } from "components/shadcn/ui/drawer"
import { classNames } from "lib/classNames"
import { Fragment, useState } from "react"

export interface PopupArgs {
	/** Determines if popup is showing or not */
	open?: boolean
	title?: string
	closeable?: boolean
	darkenUI?: boolean
	initialFocus?: any
	onClose?: () => void
	className?: string
	children: any
	// defaults to true,
	// controls whether or not input is allowed while the drawer is open
	modal?: boolean
}

export default function Popup(args: PopupArgs) {
	const [closeable, setCloseable] = useState(args.closeable ? args.closeable : true)
	const isDesktop = useBreakpoint("md")
	const { initialFocus = null } = args

	function handleClose() {
		if (closeable) {
			args.onClose?.()
		}
	}

	const isOpen = args.open === true

	if (!isDesktop) {
		return (
			<Drawer open={args.open} onClose={handleClose} modal={args.modal === undefined ? true : args.modal}>
				<DrawerContent className={classNames("rounded-b-none", "glass", "pb-4 md:px-8 md:pb-8")}>
					{args.children}
				</DrawerContent>
			</Drawer>
		)
	}

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-[100]" initialFocus={initialFocus} onClose={handleClose}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm transition-all dark:bg-opacity-80" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-1 text-center sm:items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								className={"relative w-full transform overflow-hidden text-left transition-all sm:my-8"}>
								<div className={classNames("glass", "rounded-2xl p-6", "relative mx-auto", args.className)}>
									{closeable && (
										<div
											id="popup-close-btn"
											className="group absolute right-0 top-0 cursor-pointer p-6"
											onClick={handleClose}>
											<XMarkIcon className="w-5 group-hover:text-[#5901FB] dark:text-gray-300" />
										</div>
									)}
									{args.title && (
										<div className="mb-6 text-sm font-bold  dark:text-gray-300">{args.title}</div>
									)}

									{args.children}
								</div>
							</Dialog.Panel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
