import { PlusIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "./Tooltip"
import { classNames } from "lib/classNames"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { useEffect, useState } from "react"
import { trpc } from "lib/trpc/trpc"
import { DisplayInfo } from "./device-management/types"
import toast from "react-hot-toast"
import { TRPCError } from "@trpc/server"
import { toastErrorStyle, toastSuccessStyle } from "./toastStyles"
import React from "react"
import { useBlocksUserStore } from "@/hooks/useStore"

export default function AddHologramToGoButton(args: {
	hologram: { id: number; title: string }
	className?: string
}) {
	const { hologram, className } = args
	const [displays, setDisplays] = useState<DisplayInfo[]>([])
	const user = useBlocksUserStore((state) => state.dbUser)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const { data, fetchNextPage, hasNextPage, isRefetching, isFetchingNextPage } =
		trpc.displays.myDisplays.useInfiniteQuery(
			{ limit: 20 },
			{
				enabled: !!user,
				getNextPageParam: (lastPage) => lastPage.nextCursor,
			},
			// { refetchInterval: 30000 },
		)

	useEffect(() => {
		if (!data) return
		// prevents us from updating the state when we're fetching the next page
		if (data.pages.length === 1 || !isFetchingNextPage) {
			setDisplays(data.pages.flatMap((page) => page.displays).filter((display) => !!display))
		}
	}, [data])

	const { mutateAsync: addHologramsToPlaylist } = trpc.playlistItem.addHologramsToPlaylists.useMutation()

	if (displays.length === 0 || !hologram) {
		return null
	}

	return (
		<>
			<DropdownMenu.Root onOpenChange={setIsMenuOpen} open={isMenuOpen} modal={true}>
				<style jsx global>
					{`
						.DropdownMenuContent {
							animation-duration: 0.6s;
							animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
						}
						.DropdownMenuContent[data-side="bottom"] {
							animation-name: slideDown;
						}

						[role^="menuitem"] {
							cursor: pointer;
						}

						@keyframes slideDown {
							from {
								opacity: 0;
								transform: translateY(-10px);
							}
							to {
								opacity: 1;
								transform: translateY(0);
							}
						}
					`}
				</style>

				<Tooltip content={"Add to Go"}>
					<DropdownMenu.Trigger
						className={classNames(
							className,
							"rounded-full border border-solid border-white/0 bg-white bg-opacity-20 p-3 transition-all hover:border-white/20 hover:bg-foil hover:shadow-lg",
							isMenuOpen ? "backdrop-blur-lg md:bg-white/30" : "bg-white bg-opacity-20",
						)}
						onClick={(e) => {
							e.preventDefault(), e.stopPropagation(), setIsMenuOpen(!isMenuOpen)
						}}>
						<PlusIcon
							className={classNames(
								"pointer-events-none h-5 w-5 stroke-white stroke-2 drop-shadow-lg",
								isMenuOpen ? "md:opacity-100" : null,
							)}
						/>
					</DropdownMenu.Trigger>
				</Tooltip>
				<DropdownMenu.Portal>
					<DropdownMenu.Content
						align="center"
						asChild={true}
						className={classNames(
							"DropdownMenuContent glass pointer-events-none relative mt-2 rounded-lg p-2",
						)}>
						{displays.length > 0 && (
							<DropdownMenu.DropdownMenuGroup>
								{displays.map((display) => (
									<DropdownMenu.Item
										key={display.id}
										disabled={!display.ownsPlaylist}
										className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 disabled:opacity-10 dark:hover:bg-white/10"
										onClick={async (e) => {
											e.preventDefault()
											e.stopPropagation()
											if (display.playlistId) {
												try {
													await addHologramsToPlaylist({
														hologramIds: [hologram.id],
														playlistIds: [display.playlistId],
													})

													toast(
														`added ${hologram.title} to ${display.name ?? "Looking Glass Go"}`,
														toastSuccessStyle,
													)
													setIsMenuOpen(false)
												} catch (e) {
													const error = e as unknown as TRPCError
													toast(
														error.message === "User does not own all the playlists"
															? "Your display is synced to someone else's playlist"
															: error.message,
														toastErrorStyle,
													)
													setIsMenuOpen(false)
												}
											}
										}}>
										<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
											{/* <PencilIcon className="w-4" /> */}
											<p className="font-medium">{display.name ?? `Looking Glass Go (${display.serial})`}</p>
										</DropdownMenu.Label>
									</DropdownMenu.Item>
								))}
							</DropdownMenu.DropdownMenuGroup>
						)}
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		</>
	)
}
