import { classNames } from "lib/classNames"

interface RadioIconArgs {
	selected: boolean
}

export default function RadioIcon(args: RadioIconArgs) {
	return (
		<div
			className={classNames(
				"flex h-[20px] w-[20px] flex-row items-center justify-center rounded-full",
				"border-2 border-solid border-[#60547E] bg-transparent hover:border-[#3a334d] hover:bg-black/10 dark:hover:border-[#756799] dark:hover:bg-white/10",
			)}>
			<div>
				{/* indicator */}
				{args.selected && (
					<div className="flex h-4 w-4 flex-row items-center justify-center rounded-full bg-white">
						<div className="h-3/4 w-3/4 rounded-full bg-gradient-to-l from-[#5901FB] to-[#9E53FB] bg-[length:200%_100%] bg-[position:100%_0]" />
					</div>
				)}
			</div>
		</div>
	)
}
