import { useState } from "react"
import Popup from "components/Popup"

import toast from "react-hot-toast"

import { toastSuccessStyle } from "./toastStyles"
import { TrashIcon } from "@heroicons/react/24/outline"
import { trpc } from "lib/trpc/trpc"

interface PopupProps {
	ids: number[]
	open: boolean
	setOpen: (value: number[] | null) => void
	onDelete?: () => void
}

export default function DeleteHologramPopup(args: PopupProps) {
	const { onDelete, ids } = args
	const { mutateAsync: deleteHolograms } = trpc.hologram.deleteHolograms.useMutation()
	const utils = trpc.useUtils()

	function onClose() {
		args.setOpen(null)
	}

	const [deleting, setDeleting] = useState(false)

	async function handleDelete() {
		setDeleting(true)
		onDelete?.()

		onClose()
		await deleteHolograms({ hologramIds: ids })
		await utils.hologram.myHolograms.invalidate()

		toast("Deleted Hologram", { ...toastSuccessStyle, icon: <TrashIcon className="mx-2 w-6 stroke-white" /> })
		setDeleting(false)
	}

	if (args.ids.length === 0) {
		return <></>
	}

	return (
		<Popup className="max-w-xl " initialFocus={false} open={args.open} onClose={onClose} title="">
			<div className="flex flex-col gap-4 p-4 ">
				<p className="font-rubik text-2xl font-bold ">
					{args.ids.length > 1 ? "Delete these holograms?" : "Delete this hologram?"}
				</p>
				<p className="text-sm">
					{" "}
					Deleting these holograms will remove{" "}
					<strong>
						{args.ids?.length} hologram{args.ids.length > 1 ? "s" : ""}
					</strong>{" "}
					from your Library
					<br />
					This can not be undone.
				</p>
				<div className="flex w-full flex-row items-center justify-end gap-4">
					<button className="primaryButtonAlt rounded-lg py-3 px-6" onClick={onClose}>
						Cancel
					</button>
					<button
						className="rounded-lg bg-red-500 py-3 px-6  disabled:bg-red-800"
						disabled={deleting}
						onClick={handleDelete}>
						Delete
					</button>
				</div>
			</div>
		</Popup>
	)
}
