import { useBannerStore } from "hooks/useBannerStore"
import { useCastStore } from "hooks/useCastStore"
import { useHoloPlayCore } from "hooks/useHoloPlayCore"
import { gtmEvent } from "lib/analytics"
import { classNames } from "lib/classNames"
import { useRef } from "react"
import styles from "./HologramCastButton.module.css"
import CastIcon from "./icons/CastIcon"
import { Tooltip } from "./Tooltip"

export interface CastButtonArgs {
	hologram: { id: number; uuid?: string | null | undefined }
	playlistItemId?: number
	className?: string
	fillColor?: string
}

export default function HologramCastButton({
	hologram,
	className,
	fillColor,
	playlistItemId,
}: CastButtonArgs) {
	const btn = useRef<HTMLButtonElement | null>(null)
	const castStatus = useCastStore((store) => store.castStatus)
	const castPlaylistItem = useCastStore((store) => store.playlistItemId)
	const castHologram = useCastStore((store) => store.hologram)
	const handleCastHologram = useCastStore((store) => store.handleCastHologram)

	const banner = useBannerStore()
	const { wipeFromLookingGlass } = useHoloPlayCore()

	async function onClick(e: any) {
		e.preventDefault()
		e.stopPropagation()
		if (!hologram) return
		// stop casting if we're already casting this hologram
		if (castStatus === "ACTIVE" && castHologram?.id == hologram?.id) {
			wipeFromLookingGlass()
			banner.setOpen(false)
			return
		}

		await handleCastHologram(hologram.id, hologram.uuid ?? undefined, playlistItemId)

		gtmEvent("cast-hologram-btn")
	}

	// TOOL TIP LOGIC
	const isHologram = castHologram?.id == hologram?.id
	const isCastingPlaylistItem = playlistItemId !== undefined && playlistItemId === castPlaylistItem
	const isCastingHologram = isHologram && castStatus === "ACTIVE"

	return (
		<Tooltip
			content={
				(isCastingHologram && !isCastingPlaylistItem) || isCastingPlaylistItem
					? "Stop casting"
					: "Cast to display"
			}>
			<button
				ref={btn}
				id="cast-btn"
				className={classNames(
					className?.match(/bg-/) ? "" : "bg-white",
					// display this state when we're casting the hologram
					(isCastingHologram && !isCastingPlaylistItem) || isCastingPlaylistItem ? styles.pulse : "",
					className,
				)}
				onClick={onClick}>
				<CastIcon
					animate={castStatus === "PENDING_HOLOGRAM" || castStatus === "PENDING_PLAYLIST"}
					className={classNames(
						"h-5 w-5 translate-y-[1.5px]",
						castHologram?.id == hologram?.id ? "fill-green-400" : "",
					)}
					fillColor={fillColor}
				/>
			</button>
		</Tooltip>
	)
}
