import { ClipboardDocumentCheckIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline"
import { gtmEvent } from "lib/analytics"
import { useEffect, useRef, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-hot-toast"
import { FacebookShareButton } from "./FacebookShareButton"
import { LinekdinShareButton } from "./LinkedinShareButton"
import Popup, { PopupArgs } from "./Popup"
import { TwitterShareButton } from "./TwitterButton"
import { Hologram } from "graphql/client/gql/types"
import { getPermalinkUrl } from "lib/utils.hologram"
import { getEmbedCode } from "lib/utils.embed"
import { GetUserUsernameOptions } from "lib/utils.user"
import { toastSuccessStyle } from "./toastStyles"

export interface SharePopupHologram
	extends Pick<Hologram, "id" | "uuid" | "title" | "aspectRatio" | "privacy"> {
	user: GetUserUsernameOptions
}
interface Args {
	hologram?: SharePopupHologram
	popup: Partial<PopupArgs>
}

export default function ShareHologramPopup(args: Args) {
	const focus = useRef()

	const [embedCopied, setEmbedCopied] = useState(false)
	const [linkCopied, setLinkCopied] = useState(false)

	function onCopyClick() {
		setLinkCopied(true)
		setEmbedCopied(false)
		toast("Link copied to clipboard!", toastSuccessStyle)
	}

	function onEmbedCopyClick() {
		setEmbedCopied(true)
		setLinkCopied(false)
		toast("Embed code copied to clipboard!", toastSuccessStyle)
	}

	function preventFormSubmit() {}

	useEffect(() => {
		if (args.popup.open) {
			gtmEvent("share-hologram-btn")
		}
	}, [args.popup.open])

	if (!args.hologram) {
		return <></>
	}

	const permalink = getPermalinkUrl(args.hologram.user, args.hologram)
	const embedCode = getEmbedCode(args.hologram)

	return (
		<Popup title={`Share ${args.hologram?.title}`} initialFocus={focus} className="max-w-xl" {...args.popup}>
			<div className="space-y-6 px-4 md:px-0">
				<div className="w-full flex-none flex-row space-y-4 md:flex md:space-y-0 md:space-x-2">
					{/* Copy link */}
					<div className="flex-1 space-y-2">
						<p className="text-sm font-bold opacity-40 ">Link</p>
						<CopyToClipboard text={permalink} onCopy={onCopyClick}>
							<button className="group relative w-full flex-1" onClick={preventFormSubmit}>
								<div className="flex cursor-pointer flex-row">
									<input
										type="text"
										value={permalink}
										className="field disabled h-14 w-full cursor-pointer"
										readOnly={true}
									/>
									<div>
										<div className="absolute right-0 m-2 h-10">
											<div className="primaryButton relative flex h-full items-center !py-0 group-hover:opacity-100 md:opacity-0">
												{linkCopied ? (
													<ClipboardDocumentCheckIcon className="w-5" />
												) : (
													<ClipboardDocumentListIcon className="w-5" />
												)}
											</div>
										</div>
									</div>
								</div>
							</button>
						</CopyToClipboard>
					</div>
					{/* Social */}
					<div className="space-y-2">
						<p className="text-sm font-bold opacity-40 ">Social</p>
						<div className="flex h-14 flex-row space-x-2">
							<TwitterShareButton hologram={args.hologram} />
							<FacebookShareButton hologram={args.hologram} />
							<LinekdinShareButton hologram={args.hologram} />
						</div>
					</div>
				</div>

				<div className="space-y-2">
					<p className="text-sm font-bold opacity-40 ">Embed</p>
					<CopyToClipboard text={embedCode} onCopy={onEmbedCopyClick}>
						<button className="group relative w-full flex-1" onClick={preventFormSubmit}>
							<div className="flex cursor-pointer flex-row">
								<textarea
									value={`${embedCode}`}
									className="field disabled h-20 w-full cursor-pointer font-mono !text-xs"
									readOnly={true}
								/>
								<div>
									<div className="absolute right-0 m-2 h-16">
										<div className="primaryButton relative flex h-full items-center !py-0 group-hover:opacity-100 md:opacity-0">
											{embedCopied ? (
												<ClipboardDocumentCheckIcon className="w-5" />
											) : (
												<ClipboardDocumentListIcon className="w-5" />
											)}
										</div>
									</div>
								</div>
							</div>
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</Popup>
	)
}
