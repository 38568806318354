import { trpc } from "@/lib/trpc/trpc"
import { Hologram } from "graphql/client/gql/types"
import { useRef } from "react"
import toast from "react-hot-toast"
import DownloadIcon from "./icons/DownloadIcon"
import { Linky } from "./Linky"
import Popup, { PopupArgs } from "./Popup"
import SpinnerLoader from "./SpinnerLoader"
import { toastSuccessStyle } from "./toastStyles"

interface DownloadPopupHologramArgs extends Pick<Hologram, "id" | "privacy" | "uuid" | "canUsersDownload"> {}

export interface DownloadPopupHologram
	extends Pick<
		Hologram,
		| "id"
		| "canUsersDownload"
		| "uuid"
		| "privacy"
		| "sourceImages"
		| "generatedQuilts"
		| "rgbdAssets"
		| "previewGifAssets"
		| "previewVideoAssets"
		| "type"
	> {}
interface Args {
	hologram?: DownloadPopupHologramArgs | null
	popup: Partial<PopupArgs>
}

export default function DownloadPopup(args: Args) {
	const { data: hologram, isLoading } = trpc.hologram.getById.useQuery(args.hologram?.id.toString() ?? "", {
		enabled: !!args.hologram,
	})

	const cancelButtonRef = useRef(null)

	if (!hologram) {
		return null
	}
	const { imageAssets } = hologram

	return (
		<>
			<Popup
				title={`Download: ${hologram?.title ?? ""}`}
				initialFocus={cancelButtonRef}
				className="max-w-4xl"
				{...args.popup}>
				{isLoading && <SpinnerLoader className="mx-auto w-6" />}
				<div className={"py-4"}>
					{imageAssets.map((asset, index) => {
						return (
							<DownloadOption
								index={index}
								key={index}
								url={asset.url}
								kind={asset.kind}
								fileSize={asset.fileSize}
								dimensions={`${asset.width} x ${asset.height}`}
								type={asset.type}
							/>
						)
					})}
				</div>
			</Popup>
		</>
	)
}

type DownloadOptionArgs = {
	index: number
	url?: string
	kind: string
	fileSize: number
	dimensions: string
	type: string
}

export function DownloadOption(args: DownloadOptionArgs) {
	let backgroundOpacity =
		args.index % 2 === 0 ? "bg-opacity-5 dark:bg-opacity-5" : "bg-opacity-0 dark:bg-opacity-0"
	let label = ""
	// gifs and mp4s both have "NONE" as their kind, some quilts also return none, so we use the first index to name source
	if (args.kind == "SOURCE" || args.index == 0) {
		label = "Source"
	} else if (args.kind == "RGBD") {
		label = "RGBD"
	} else if (args.kind == "DEPTH") {
		label = "Depth"
	} else if (args.kind == "GENERATED_QUILT") {
		label = "Quilt"
	} else if (args.kind == "NONE") {
		label = "Animated Preview"
	}

	return (
		<div
			className={`mb-2 flex flex-row items-center justify-between rounded-lg bg-[#1F1E37] dark:bg-white ${backgroundOpacity} mx-2 py-3 px-4 md:mx-0`}>
			<p className={"gap-4 rounded-md p-3 font-bold  md:py-3"}>{label}</p>
			<div className="flex flex-row items-center gap-6">
				<p className="text-sm ">
					{args.dimensions} | {(args.fileSize * 0.000001).toFixed(1) + " MB"}
				</p>
				<Linky
					href={encodeURI(args.url || "")}
					className="grow text-right text-sm text-[#1F1E37] dark:text-white">
					<button
						className="justify-middle flex flex-row items-center gap-[6px] rounded-xl bg-[#1F1E37] bg-opacity-80  py-2 px-5 font-bold text-white hover:bg-gradient-to-l hover:from-[#5901FB] hover:to-[#9E53FB] hover:bg-[length:200%_100%] hover:bg-[position:100%_0] hover:drop-shadow-lg dark:bg-white dark:bg-opacity-10 md:py-3 md:pr-12 md:pl-16"
						onClick={() => {
							toast(`Downloading ${label}`, { ...toastSuccessStyle, icon: <DownloadIcon className="w-6" /> })
						}}>
						Download
						<DownloadIcon className="w-6" />
					</button>
				</Linky>
			</div>
		</div>
	)
}
