import React from "react"
import { twMerge } from "tailwind-merge"

interface Args {
	children: any
	className?: string
	labelClassName?: string
}

const Badge = React.forwardRef<HTMLSpanElement, Args>(
	({ children, className, labelClassName, ...props }, ref) => {
		return (
			<span
				ref={ref}
				{...props}
				className={twMerge(
					`bg-holo-light dark:bg-holo5 inline-block rounded-sm p-1 uppercase leading-[8px]`,
					className,
				)}>
				<span
					className={twMerge(
						`text-xs font-semibold leading-[8px] text-white drop-shadow-md dark:text-dark-purple-200`,
						labelClassName,
					)}>
					{children}
				</span>
			</span>
		)
	},
)

Badge.displayName = "Badge"

export default Badge
