import { gtmEvent } from "lib/analytics"
import { classNames } from "lib/classNames"
import { trpc } from "lib/trpc/trpc"
import { Tooltip } from "./Tooltip"
import TwitterIcon from "./icons/TwitterIcon"
import { getPermalinkUrl } from "lib/utils.hologram"
import { Privacy } from "@prisma/client"

export type ShareHologram = {
	id: number
	title: string
	user: { id: number; username?: string | null; uuid: string }
	privacy: Privacy
}

interface Args {
	hologram: ShareHologram
	className?: string
}

export function TwitterShareButton({ hologram, className = "" }: Args) {
	const permalink = getPermalinkUrl(hologram.user, hologram)
	const url = encodeURIComponent(permalink)

	const query = trpc.hologram.tweetMessage.useQuery({
		id: hologram.id,
	})

	const defaultText = `${hologram.title?.replace("#", "")} on @LKGGlass`
	const text = encodeURIComponent(query?.data ?? defaultText)

	const onClick = () => {
		window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, "_blank")
		gtmEvent("twitter-btn")
	}

	return (
		<Tooltip content={"Share on Twitter"}>
			<button
				id="twitter-btn"
				className={classNames("primaryButton !px-4", className)}
				type="button"
				role="button"
				// title="Share on Twitter"
				onClick={onClick}>
				<TwitterIcon className="w-5 fill-dark-purple-100 dark:fill-white" />
			</button>
		</Tooltip>
	)
}
