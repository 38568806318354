import { gtmEvent } from "lib/analytics"
import { getPermalinkUrl } from "lib/utils.hologram"
import { Tooltip } from "./Tooltip"
import { ShareHologram } from "./TwitterButton"
import LinkedInIcon from "./icons/LinkedInIcon"

interface Args {
	hologram: ShareHologram
	className?: string
}

export function LinekdinShareButton({ hologram, className = "" }: Args) {
	const permalink = getPermalinkUrl(hologram.user, hologram)
	const url = encodeURIComponent(permalink)
	function onClick() {
		window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "_blank")
		gtmEvent("linkedin-btn")
	}

	return (
		<Tooltip content={"Share on LinkedIn"}>
			<button id="linkedin-btn" className="primaryButton !px-4" onClick={onClick}>
				<LinkedInIcon className="w-5" />
			</button>
		</Tooltip>
	)
}
