import { gtmEvent } from "lib/analytics"
import { getPermalinkUrl } from "lib/utils.hologram"
import { Tooltip } from "./Tooltip"
import { ShareHologram } from "./TwitterButton"
import FacebookIcon from "./icons/FacebookIcon"
interface Args {
	hologram: ShareHologram
	className?: string
}
export function FacebookShareButton({ hologram, className = "" }: Args) {
	const permalink = getPermalinkUrl(hologram.user, hologram)
	const url = encodeURIComponent(permalink)
	function onClick() {
		window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, "_blank")
		gtmEvent("facebook-btn")
	}

	return (
		<Tooltip content={"Share on Facebook"}>
			<button id="facebook-btn" className="primaryButton !px-4" onClick={onClick}>
				<FacebookIcon className="w-5" />
			</button>
		</Tooltip>
	)
}
