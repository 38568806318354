import { ImageAsset } from "@prisma/client"
import { PreviewCardFragment } from "graphql/client/gql/types"
import Image, { ImageProps } from "next/image"
import { useState } from "react"
import { twMerge } from "tailwind-merge"
import { MathUtils } from "three"
import styles from "./PreviewCard/PreviewCard.module.css"
import { EmbedHologram } from "lib/hologramProps"
import { CDNOptions, getCDNUrl } from "@/lib/cdn"
import { getCenterRectFromQuilt } from "@/lib/utils.edge"
import { findSourceImage } from "@/prisma/models"
import { getThumbnail } from "@/lib/utils.hologram"

export type HologramThumbnailData = {
	title?: string | null
	aspectRatio: number | null
	type: "QUILT" | "RGBD"
	sourceImages?: PreviewCardFragment["sourceImages"]
	imageAssets?: Pick<ImageAsset, "url" | "width" | "height" | "type" | "kind">[]
	quiltRows: number | null
	quiltCols: number | null
	quiltTileCount: number | null
}

interface HologramThumbnailProps extends Omit<ImageProps, "src" | "loader" | "width" | "alt"> {
	hologram: EmbedHologram
	width: number
	height?: number
	containerWidth?: number
	onImageLoaded?: () => void
	containerClassName?: string
	imageClassName?: string
}

export const getCDNRect = (args: { hologram: any } & CDNOptions) => {
	const { width, height, blur, hologram } = args
	const sourceImage = findSourceImage(hologram)
	const rect = getCenterRectFromQuilt(hologram, sourceImage!)
	const [x, y, w, h] = rect.split("-").map(Number)
	return getCDNUrl(sourceImage!.url, {
		width,
		height,
		rect: { x, y, w, h },
		blur,
	})
}

export default function HologramThumbnail(props: HologramThumbnailProps) {
	const {
		hologram,
		onImageLoaded,
		height,
		containerClassName,
		imageClassName,
		containerWidth,
		...imageProps
	} = props
	const { title, type } = hologram
	const aspectRatio = hologram.aspectRatio ?? 0.75

	const [isLoading, setIsLoading] = useState(true)

	const onLoad = () => {
		const delay = MathUtils.randInt(0, 300)
		setTimeout(() => setIsLoading(false), delay)
		onImageLoaded?.()
	}

	const thumbnailUrl = getThumbnail(hologram, imageProps.width)?.url ?? ""

	return (
		<div
			style={{ height: props.height, width: containerWidth }}
			className={twMerge(
				"pointer-events-none relative h-auto w-full overflow-hidden rounded-lg transition-all duration-700",
				"h-full w-full",
				containerClassName,
			)}>
			<Image
				{...imageProps}
				placeholder="empty"
				onLoad={onLoad}
				src={thumbnailUrl}
				alt={title ?? ""}
				height={height ?? imageProps.width / aspectRatio}
				className={twMerge(
					`h-full w-full rounded-lg transition-all duration-700`,
					isLoading ? "scale-75 opacity-0" : "scale-100 opacity-100",
					height ? "object-cover" : null,
					imageClassName,
					"absolute left-1/2 top-1/2 h-auto min-h-full w-auto -translate-x-1/2 -translate-y-1/2 object-cover",
				)}
			/>
			<div
				className={twMerge(
					"absolute top-0 h-full w-full",
					styles.loading,
					isLoading ? "opacity-100" : "opacity-0",
				)}
			/>
		</div>
	)
}
